
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import Datarow from "@/components/c-datatable/Datarow.vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import { FormTypes } from "@/core/enums/form-types";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Select from "@/components/forms/Select.vue"
import { Form } from "vee-validate";
import moduleTable from '@/store/modules/physicalInventory/modules/movement-preview'
import { getOptions, MovementType } from '@/core/enums/movement-types'
import FiltersMovementPreview from "@/models/physicalInventory/filters/FiltersMovementPreview";
import { useRouter } from 'vue-router'
import MovementPreviewRow from "@/models/physicalInventory/MovementPreviewRow";
import { RouteNames } from "@/router/route-names";
import { useStore } from "vuex";

export default defineComponent({
    components:{
        Datatable
        , Datarow
        , ModuleMaster
        , Select
        , Form
    },
    setup(){

        //PROPIEDADES
        const showFilters = ref(false);
        const store = useStore();
        const router = useRouter();
        let modeForm = ref(FormTypes.Create);
        const canApprovalWarehouseManager = computed(() => store.getters["canManagerWarehouseApproval"]);
        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "warehouseName", HeadLabel: "Almacen", Type: "text", VisibleInGrid: true },
            { PropName: "movementTypeName", HeadLabel: "Tipo de movimiento", Type: "text", VisibleInGrid: true },//movementType -> Entrada/Salida
            { PropName: "createdAt", HeadLabel: "Fecha Creación", Type: "text", VisibleInGrid: true },          
            { PropName: "statusName", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true },          
        ]);   

        //METODOS
        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
            //console.log(`call handleChangePage(${page})`);   
        }
      

        const searchMovements = (filters: Partial<FiltersMovementPreview>) => { 
            moduleTable.UPDATE_FILTERS(filters);
        }
        const goToRoute = (row: MovementPreviewRow) => { 
            let routeName = '', query = {}, params = {};
            if (row.eMovementType == MovementType.Inputs) {
                routeName = RouteNames.InputsDetails
                let dataProps = `inputId:${row.id}|canEdit:${row.status == 0}|movementFolio:${row.folio}|requireCI:${row.status == 1}|requireCF:${!(row.status == 1)}|requireAWM:${canApprovalWarehouseManager.value && row.status == 1}`;
                params = {
                      inputId: btoa(dataProps),
                    //  canEdit: canEdit(movementeId),
                    movementFolio: row.folio
                };
            } else if (row.eMovementType == MovementType.Outputs) { 
                routeName = RouteNames.OutputsDetails
                let dataProps = `outputId:${row.id}|canEdit:${row.status == 0}|movementFolio:${row.folio}|requireDvy:${row.status == 0}|requireCmt:${row.status == 4}|requireAWM:${canApprovalWarehouseManager.value && (row.status == 1 /*&& !movement.whereHouseManagerApproval*/)}`;
                params = {
                    outputId: btoa(dataProps),
                    canEdit: row.status == 0,
                    movementFolio: row.folio
                };
            } else if (row.eMovementType == MovementType.Transfer) { 
                 routeName = RouteNames.TransfersDetails
                query = {
                    transferId: row.id
                };
            }
            
            const routeData = router.resolve({ name: routeName, query: query, params: params });
            window.open(routeData.href, '_blank');
        }

        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(moduleTable.records, configTable);
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Consulta de movimientos", ["Módulos", "Invetario Fisico"]);
            moduleTable.SEARCH_TABLE_DATA();
            moduleTable.getComboWarehouse('')
        })

        //DATOS DEL STORE
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);

        const warehouseOptions = computed(() => moduleTable.selects.warehouseOptions)
        const statusOptions = computed(() => getOptions().filter( a => [MovementType.Inputs, MovementType.Outputs, MovementType.Transfer].includes(+a.id)))
        const loadingWarehouseOptions = computed(() => moduleTable.loadings.warehouseOptions)

        return {
            showFilters
            , totalRecords
            , sizePage
            , headers
            , renderRows
            , modeForm
            , searchMovements
            , handleChangePage
            , goToRoute

            , warehouseOptions
            , statusOptions
            , loadingWarehouseOptions
        }
    }
})
