export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "SEARCH_MOVEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar movimientos"])}
        },
        "TOOLTIPS": {
          "GOTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a los detalles"])}
        },
        "LABELS": {
          "STORAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacen"])},
          "MOVEMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de movimiento"])},
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda avanzada"])}
        }
      }
    }
  })
}
