import PaginationRequest from '@/models/general/PaginationRequest';

import FiltersMovementPreview from "@/models/physicalInventory/filters/FiltersMovementPreview";
import CatalogService from "../general/CatalogService";
import SwalMessageService from "../SwalMessageService";
import MovementPreviewRow from "@/models/physicalInventory/MovementPreviewRow";
import ApiService from '../ApiService';
import PaginationResponse from '@/models/general/PaginationResponse';
import { AxiosResponse } from 'axios';
import MessageService from '../interfaces/MessageService';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';

export default class WareHouseMovementService {

    apiController: string;
    messageService: MessageService;

    constructor(){
        this.apiController = "/WareHouseMovements";
        this.messageService = new SwalMessageService()
    }

    async getTable(filters: FiltersMovementPreview, pagination: PaginationRequest): Promise<AxiosResponse<PaginationResponse<MovementPreviewRow>>> {
        
        return ApiService.get<PaginationResponse<MovementPreviewRow>>(this.apiController, 
            `GetPagedList?`
            + `CurrentPage=${pagination?.currentPage ?? 1}`
            + `&PageSize=${pagination?.pageSize ?? 10}`
            + (filters?.werehouseId ?? '' ? `&Criteria.WareHouseId=${filters?.werehouseId ?? ''}` : '')
            + (filters.EMovementType != undefined && !isNaN(filters.EMovementType) ? `&Criteria.EMovementType=${filters.EMovementType}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
     }

}