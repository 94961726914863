import PaginationRequest from "@/models/general/PaginationRequest";
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import { Mutations } from '../../../../commons/MutationsTable';
import store from '@/store';
import Modules from "@/store/enums/Modules";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import { Actions } from "@/store/commons/ActionsTable";
import PopulationRow from "@/models/population/PopulationRow";
import MovementPreviewState from "../../model/MovementPreviewState";
import OptionSelect from "@/models/shared/OptionSelect";
import FiltersMovementPreview from "@/models/physicalInventory/filters/FiltersMovementPreview";
import WareHouseMovementService from "@/core/services/wareHouseMovements/warehouseMovementService";
import LoadingsMovementPreview from "@/models/physicalInventory/LoadingsMovementPreview";
import WarehouseComboService from "@/core/services/werehouse/WarehouseComboService";

const defaultFilters = {
    werehouseId: ''
} as FiltersMovementPreview;

const enum MutationsOwner  {
    SET_LOADINGS = "SET_LOADINGS"
}
@Module({dynamic: true, store, namespaced: true, name: Modules.MovementPreviewModule})
class MovementPreviewModule extends VuexModule implements MovementPreviewState {
    loadings = {
        table: false
        , warehouseOptions: false
    };
    selects = {
        warehouseOptions: [] as OptionSelect[]
    };

    records = [];
    totalRecords = 0;
    filters = defaultFilters;
    pagination = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    loading = false;

    @Mutation
    [MutationsOwner.SET_LOADINGS](loadings: Partial<LoadingsMovementPreview>){
        SaveValuesToStore(this.loadings, loadings)
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: []){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: Partial<PaginationRequest>){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersMovementPreview>){
        SaveValuesToStore(this.filters, filters, true);

    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

    /**
     * Se obtienen los datos de la tabla mediante la paginación
     * @param paginator paginador de las notas
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
         this.context.commit(MutationsOwner.SET_LOADINGS, { table: true});

         const resp = (await service.getTable(this.filters, this.pagination)
         .finally( () => this.context.commit(MutationsOwner.SET_LOADINGS, { table: true}))).data ??
             {
                 data: [] as PopulationRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
             }
 
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalPages);
    }

    /**
       * Realiza un busqueda con la nueva paginación
       * @param pagination paginación de la tabla
       */
     @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Filtros que se aplican y despues se hace la busqueda de los datos
     * @param filters filtros nuevos
     */
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
        this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Cambia el estatus de la entrada
     * @param id identificador de la entrada
     * @param newStatus nuevo estatus a asignarle
     */
    /*@Action
    async [Actions.CHANGE_STATUS](payload: { id:string, newStatus: boolean}){
        
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.changeStatus(payload.id, payload.newStatus)
        .then(() => this.context.dispatch(Actions.SEARCH_TABLE_DATA))
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false));
        
    }*/

    @MutationAction
    async getComboWarehouse(filterName: string) {
        this.context.commit(MutationsOwner.SET_LOADINGS, { warehouseOptions: true });
        const states = await serviceWarehouseCombo.searchCombo(filterName)
            .finally(() => {
                this.context.commit(MutationsOwner.SET_LOADINGS, { warehouseOptions: false });
            });
        const selects = { ...this.selects }
        return { selects: { ...selects, warehouseOptions: states.data ?? [] } }
    }
    
}

/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
const service = new WareHouseMovementService();
const serviceWarehouseCombo = new WarehouseComboService();
 

export default getModule(MovementPreviewModule);